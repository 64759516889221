import * as React from "react";

const AccessRight = ({ user }) => {
  const renderFlag = (bool) =>
    bool ? (
      <i className="fas fa-check" style={{ color: "green" }}></i>
    ) : (
      <i className="fas fa-times" style={{ color: "red" }}></i>
    );

  return (
    <>
      <div className="mt-3">Event Access Rights:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-2">
          <span className="fw-bold">3-Day Pass:&nbsp;</span>
          {renderFlag(user.eventAccess)}
        </div>
        <div className="mb-2">
          <span className="fw-bold">Cerebral Pass:&nbsp;</span>
          {renderFlag(user.cerebralAccess)}
        </div>
        <div className="mb-2">
          <span className="fw-bold">BNC Pass:&nbsp;</span>
          {renderFlag(user.bncAccess)}
        </div>
      </div>
    </>
  );
};

export default AccessRight;
