import * as React from 'react'
import SessionSpeaker from './SessionSpeaker'
import AddToCalendar from '@culturehq/add-to-calendar'
import { FACULTY_TYPE, ProgrammeSession } from '../Programme'

const SessionCard: React.FC<ProgrammeSession> = ({
  id,
  title,
  startAt,
  endAt,
  date,
  time,
  day,
  hall,
  hallOverwrite,
  faculties,
  subsessions,
  desc,
  ended,
}) => {
  const [dropdown, setDropdown] = React.useState(
    document.querySelector('.chq-atc--dropdown')
  )

  React.useEffect(() => {
    if (dropdown) {
      dropdown.querySelectorAll('a').forEach((node) => {
        node.setAttribute('class', `${id}`)
      })
    }
  }, [dropdown])

  const getDropdown = () => {
    setDropdown(document.querySelector('.chq-atc--dropdown'))
  }

  const listenToBtns = (e) => {
    setTimeout(getDropdown, 500)
  }

  return (
    <div
      className="card mb-2 p-3 border-2"
      style={{ backgroundColor: ended ? '#f4f4f4' : 'white' }}
    >
      <div className="row">
        <div className="col-7">
          <p className="fw-bold mb-2">{date}</p>
          <p className="fw-bold mb-2">{time}</p>
          <small className="d-block mb-2 fw-bold fst-italic text-muted">
            {day} | {hallOverwrite ? hallOverwrite : hall}
          </small>
        </div>
        <div className="col-5 pt-1">
          <AddToCalendar
            children={
              <small
                onClick={(e) => listenToBtns(e)}
                className="d-block ms-1"
              >
                ADD TO CALENDAR
              </small>
            }
            filename={title}
            event={{
              name: title,
              details: 'MSH 2024',
              location: hall,
              startsAt: startAt,
              endsAt: endAt,
            }}
          />
        </div>
      </div>

      <p className="fw-bold text-danger mb-2">{title}</p>
      {desc ? (
        <p className="my-2" dangerouslySetInnerHTML={{ __html: desc }} />
      ) : null}

      {faculties.length ? (
        <div className="mt-2">
          {FACULTY_TYPE.map((ft) => {
            const persons = faculties.filter(
              ({ facultyType }) => facultyType === ft
            )
            if (persons.length) {
              return (
                <div className="mb-3" key={ft}>
                  <small className="d-block mb-2 fw-medium fst-italic">{ft}</small>
                  {persons.map(({ id, fullName, avatar, shortProfile }) => (
                    <SessionSpeaker
                      id={id}
                      key={id}
                      fullName={fullName}
                      avatar={avatar}
                      shortProfile={shortProfile}
                    />
                  ))}
                </div>
              )
            }
          })}
        </div>
      ) : null}

      {subsessions.length > 0 && (
        <>
          <div className="subsessions">
            {subsessions.map((ss) => (
              <div className="card border bg-light p-3 mb-2" key={ss.id}>
                {ss.time && (
                  <div className="fw-bold text-primary mb-2">{ss.time}</div>
                )}
                <div className="fw-bold text-danger">{ss.title}</div>
                {ss.description && (
                  <small
                    className="d-block mt-1"
                    dangerouslySetInnerHTML={{ __html: ss.description }}
                  />
                )}
                {ss.speakers.length ? (
                  <div className="">
                    <small className="d-block mb-2 mt-3 fw-medium fst-italic">
                      Speakers
                    </small>
                    {ss.speakers.map(
                      ({ id, fullName, avatar, shortProfile }) => (
                        <SessionSpeaker
                          id={id}
                          key={id}
                          fullName={fullName}
                          avatar={avatar}
                          shortProfile={shortProfile}
                        />
                      )
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SessionCard
