import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import HallSessions from "./HallSessions";

export const DAY_ONE_HALLS: Array<ProgrammeSession["hall"]> = [
  "ASAD Track",
  "MSC Track",
  "MSN Track",
  "CCNDP Track",
  "BNC Track",
  "Allied Health Track",
];
export const DAY_TWO_HALLS: Array<ProgrammeSession["hall"]> = [
  "ASAD Track",
  "MSC Track",
  "MSN Track",
  "CCNDP Track",
  "BNC Track",
  "Allied Health Track",
];
export const DAY_THREE_HALLS: Array<ProgrammeSession["hall"]> = [
  "ASAD Track",
  "MSC Track",
  "MSN Track",
  "CCNDP Track",
  "BNC Track",
  "Allied Health Track",
];

export const HALL_CATEGORY_1: Array<ProgrammeSession["hall"]> = [
  "ASAD 1 Track",
  "ASAD 2 Track",
];
// export const HALL_CATEGORY_2 : Array<ProgrammeSession['hall']> = ['ASAD 2 Track']
export const HALL_CATEGORY_3: Array<ProgrammeSession["hall"]> = ["MSC Track"];
export const HALL_CATEGORY_4: Array<ProgrammeSession["hall"]> = ["MSN Track"];
export const HALL_CATEGORY_5: Array<ProgrammeSession["hall"]> = ["CCNDP Track"];
export const HALL_CATEGORY_6: Array<ProgrammeSession["hall"]> = ["BNC Track"];
export const HALL_CATEGORY_7: Array<ProgrammeSession["hall"]> = [
  "Allied Health Track",
];

const EVENT_DATES = [
  { dayNum: 1, dayName: "Day 1", date: "15 Aug | Thursday" },
  { dayNum: 2, dayName: "Day 2", date: "16 Aug | Friday" },
  { dayNum: 3, dayName: "Day 3", date: "17 Aug | Saturday" },
];

export const FACULTY_TYPE = [
  "Chairperson",
  "Citation",
  "Co-Chair",
  "Emcee",
  "Judge",
  "Guest Of Honor",
  "Speaker",
  "Adjudicator",
  "Moderator",
] as const;

export enum CATEGORY {
  "asad_1",
  "asad_2",
  "msc",
  "msn",
  "ccndp",
  "bnc",
  "allied_health",
}

export interface Faculty {
  id: number;
  fullName: string;
  avatar: string;
  shortProfile: string;
}

export interface FacultyWithType extends Faculty {
  facultyType: (typeof FACULTY_TYPE)[number];
}

export interface ProgrammeSession {
  id: number;
  title: string;
  desc?: string;
  startAt: string;
  endAt: string;
  date: string; // 11 JUN | SATURDAY
  time: string; // 10:00 to 11:00
  day: 1 | 2 | 3;
  hallOverwrite?: string;
  hall:
    | "ASAD Track"
    | "ASAD 1 Track"
    | "ASAD 2 Track"
    | "MSC Track"
    | "MSN Track"
    | "CCNDP Track"
    | "BNC Track"
    | "Allied Health Track";
  category:
    | "asad_1"
    | "asad_2"
    | "msc"
    | "msn"
    | "ccndp"
    | "bnc"
    | "allied_health";
  faculties: Array<FacultyWithType>;
  subsessions: Array<ProgrammeSubsession>;
  secondsTillStart: number;
  durationInSeconds: number;
  ended?: boolean;
}

export interface ProgrammeSubsession {
  id: number;
  title?: string;
  description?: string;
  speakers: Array<Faculty>;
  time: string; // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<CATEGORY>(CATEGORY.asad_1);
  const [day, setDay] = useState(defaultDay || 1);

  const [data, setData] = useState([]);

  const [activeLecture, setActiveLecture] =
    useState<ProgrammeSession["hall"]>("ASAD 1 Track");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/programme_sessions?category=${CATEGORY[category]}&day=${day}`)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [day, category]);

  const dayClass = "btn btn-outline-dark my-1 fw-bold me-2";
  const catClass = "btn btn-outline-danger my-1 fw-bold me-2";

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case "ASAD 1 Track":
        return "#59d4e8";
      case "ASAD 2 Track":
        return "#f5b5fc";
      case "MSC Track":
        return "#96f7d2";
      case "MSN Track":
        return "#a6acec";
      case "CCNDP Track":
        return "#e8ffe8";
      case "BNC Track":
        return "#f5b5fc";
      case "Allied Health Track":
        return "#b7e2ce";
      default:
        return "";
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns d-flex flex-column align-items-center">
        {/* 1st row */}
        <div className="my-3 text-center">
          {EVENT_DATES.map(({ dayNum, dayName, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && "active"} ${dayClass}`}
              onClick={() => {
                setDay(dayNum);
              }}
            >
              <div>{dayName}</div>
              <div>{date}</div>
            </button>
          ))}
        </div>

        {/* 2nd row */}
        {(day === 1 || day === 2 || day === 3) && (
          <div className="my-3 text-center" style={{ maxWidth: "800px" }}>
            <button
              className={`${
                category === CATEGORY.asad_1 && "active"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.asad_1);
                setActiveLecture("ASAD 1 Track");
              }}
            >
              ASAD Track
            </button>
            <button
              className={`${category === CATEGORY.msc && "active"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msc);
                setActiveLecture("MSC Track");
              }}
            >
              MSC Track
            </button>
            <button
              className={`${category === CATEGORY.msn && "active"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.msn);
                setActiveLecture("MSN Track");
              }}
            >
              MSN Track
            </button>
            <button
              className={`${
                category === CATEGORY.ccndp && "active"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.ccndp);
                setActiveLecture("CCNDP Track");
              }}
            >
              CCNDP Track
            </button>
            <button
              className={`${category === CATEGORY.bnc && "active"} ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.bnc);
                setActiveLecture("BNC Track");
              }}
            >
              BNC Track
            </button>
            <button
              className={`${
                category === CATEGORY.allied_health && "active"
              } ${catClass}`}
              onClick={() => {
                setCategory(CATEGORY.allied_health);
                setActiveLecture("Allied Health Track");
              }}
            >
              Allied Health Track
            </button>
          </div>
        )}
      </div>

      {/* 3rd row */}
      {category === CATEGORY.asad_1 && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_1.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_1.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div className="col-md-6" key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* {category === CATEGORY.asad_2 && (
          <div className="mt-5">
            
            <div className="d-block d-md-none">
              {HALL_CATEGORY_2.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && 'deselected'
                  }`}
                  style={{background: getTabColor(hall), textTransform: 'capitalize' }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace('Lecture ','')}
                </button>
              ))}
            </div>
            
            <div className="row nogutters">
              {HALL_CATEGORY_2.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )} */}

      {category === CATEGORY.msc && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_3.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_3.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {category === CATEGORY.msn && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_4.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_4.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {category === CATEGORY.ccndp && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_5.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_5.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {category === CATEGORY.bnc && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_6.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_6.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {category === CATEGORY.allied_health && (
        <div className="mt-5">
          <div className="d-block d-md-none">
            {HALL_CATEGORY_7.map((hall) => (
              <button
                key={hall}
                className={`hallTabs fw-bold ${
                  activeLecture !== hall && "deselected"
                }`}
                style={{
                  background: getTabColor(hall),
                  textTransform: "capitalize",
                }}
                onClick={() => setActiveLecture(hall)}
              >
                {hall.replace("Lecture ", "")}
              </button>
            ))}
          </div>

          <div className="row nogutters">
            {HALL_CATEGORY_7.map((hall) => (
              // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
              <div key={hall}>
                <HallSessions
                  hallName={hall}
                  activeHall={activeLecture}
                  programmes={data.filter(
                    (programme: ProgrammeSession) => programme.hall === hall
                  )}
                  getTabColor={getTabColor}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Programme;
