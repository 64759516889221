import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  token,
  url,
  purchasedPhysical,
  physical,
  cerebral,
  purchasedWorkshop,
  bnc,
  sst,
  test,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedCerebral, setSelectedCerebral] = useState(null); // [null, product_id]
  const [selectedBnc, setSelectedBnc] = useState(null); // [null, product_id]

  const today = new Date();
  const march = new Date(today.getFullYear(), 2, 1); // Months are 0-indexed in JavaScript

  let sstRate = today < march ? 6 : 8;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedCerebral, selectedBnc]}
        />

        <ProductCard
          cardTitle={"ASAD 2024 Congress Ticket"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedCerebral(null);
              setSelectedBnc(null);
            }
          }}
          handleRadio={() => {
            setSelectedCerebral(null);
            setSelectedBnc(null);
          }}
        />

        {/* only can buy one workshop */}
        <h4 className="text-center text-navy mt-4">Select Workshop</h4>
        {/* ------------ cerebral CARD ------------ */}
        <ProductCard
          cardTitle={"Cerebral Palsy Workshop PNU"}
          products={cerebral}
          selectedState={selectedCerebral}
          setSelectedState={setSelectedCerebral}
          activated={
            (selected !== null && !purchasedWorkshop) || purchasedPhysical
          }
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedCerebral(null);
              setSelectedBnc(null);
            }
          }}
          handleRadio={() => {
            setSelectedCerebral(null);
            setSelectedBnc(null);
          }}
        />
        <h6 className="text-center text-muted mt-4">OR</h6>
        {/* ------------ bnc CARD ------------ */}
        <ProductCard
          cardTitle={
            "BNC Pre-Congress Workshop on Neuroethics and IBRO-supported School Enhancement Programme"
          }
          products={bnc}
          selectedState={selectedBnc}
          setSelectedState={setSelectedBnc}
          activated={
            (selected !== null && !purchasedWorkshop) || purchasedPhysical
          }
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedCerebral(null);
              setSelectedBnc(null);
            }
          }}
          handleRadio={() => {
            setSelectedCerebral(null);
            setSelectedBnc(null);
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur {sstRate}% SST
          </strong>
        )}
        <button
          type="submit"
          className="theme-btn d-block mx-auto mt-4"
          disabled={
            (!purchasedPhysical && selected === null) ||
            (purchasedPhysical &&
              selectedCerebral === null &&
              selectedBnc === null)
          }
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
